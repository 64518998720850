import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Drift } from 'react-live-chat-loader'
import LiveChatLayout from '../../components/LiveChatLayout'
import Seo from '../../components/seo'
import BackArrow from '../../icons/back-arrow.svg'

export default function Blog() {
  return (
    <LiveChatLayout>
      <Seo title="Blog" />
      <div className="drift-wrapper">
        <Drift icon="B" color="rgb(222, 48, 105)" className="circle" />
      </div>
      <section className="blog-list-section">
        <div className="blog-list-section__content">
          <div className="blog-list-section__content__header">
            <Link
              to="/"
              className="blog-list-section__content__header__back-link"
            >
              <img src={BackArrow} alt="Início" />
            </Link>
            Blog
          </div>
          <div className="blog-list-section__content__articles">
                <Link
                  className="blog-list-section__article"
                  to="./introduction-live-transcription"
                >
                  <StaticImage
                    src="../../images/blog/blog-live-transcribe.png"
                    className="blog-list-section__article__img"
                    alt="People"
                    width={328}
                    height={232}
                  />
                  <div className="blog-list-section__article__title">
                    Introdução à Transcrição ao Vivo
                  </div>
                  <div className="blog-list-section__article__date">
                    1 de Outubro de 2021
                  </div>
                </Link>
          </div>
        </div>
      </section>
    </LiveChatLayout>
  )
}
